<template>
  <Form :items="items" :scheduleData="scheduleData"/>
</template>

<script>
import Form from '../Form/Step3.vue';
export default {
  components: {
    Form
  },
  props: {
    items: {
      type: Boolean
    },
    scheduleData: {
        type: Boolean,
    }
  }
};
</script>